import React from "react";
import Home from "./pages/Home/Home";
import Nav from "./components/navBar/Nav";
import Works from "./pages/Works/Works";
import ImgWrapper from "./pages/ImgWrapper/ImgWrapper";
import About from "./pages/About/About";
import Footer from "./components/Footer/Footer";

function App() {

  return (
    <div className="App">
      <Nav />
      <Home />
      <Works />
      <ImgWrapper />
      <About />
      <Footer />
    </div>
  );
}

export default App;
