import React, { useContext, useEffect, useState } from "react";
import "./Works.css";
import { AppContext } from "../../context/AppContext";

const Works = () => {
  const { workTreshold, setWorkTreshold } = useContext(AppContext);

  useEffect(() => {
    const works = document.querySelector(".works-container");
    setWorkTreshold(works.getBoundingClientRect().top + 100);
  }, []);

  return (
    <div className="works-container">
      <div className="works-content">
        <div className="works-title">
          <h1>Proje.</h1>
        </div>
        <div className="works-gallery">
          <div className="works-gallery-item works-gallery-item-1">
            <img src="https://resmim.net/cdn/2024/04/14/fgQe1Q.webp" alt="" />
            <div className="gallery-item-title">
              <h4>Lorem ipsum dolor sit amet.</h4>
            </div>
          </div>
          <div className="works-gallery-info">
            <div className="works-gallery-info-title">Recent Projects</div>
            <div className="works-gallery-info-exp">
              Son dönemdeki çarpıcı projelerimizle sıra dışılığı yükseltiyoruz.
              Her çabamızda yaratıcılığı ve mükemmelliği ortaya çıkarıyoruz.
            </div>
          </div>
          <div className="works-gallery-item works-gallery-item-2">
            <img src="https://resmim.net/cdn/2024/04/14/fgQ0b7.webp" alt="" />
            <div className="gallery-item-title">
              <h4>Lorem ipsum dolor sit amet.</h4>
            </div>
          </div>
          <div className="works-gallery-item works-gallery-item-3">
            <img src="https://resmim.net/cdn/2024/04/14/fgQbCM.webp" alt="" />
            <div className="gallery-item-title">
              <h4>Lorem ipsum dolor sit amet.</h4>
            </div>
          </div>
          <div className="works-see-all">
            <h1>See All Work</h1>
          </div>
          <div className="works-gallery-item works-gallery-item-4">
            <img src="https://resmim.net/cdn/2024/04/14/fgQymq.webp" alt="" />
            <div className="gallery-item-title">
              <h4>Lorem ipsum dolor sit amet.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Works;
