import React, { useState, useEffect, useContext } from "react";
import "./About.css";
import { AppContext } from "../../context/AppContext";

const About = () => {

    const { aboutTreshold, setAboutTreshold } = useContext(AppContext);

    useEffect(() => {
        const about = document.querySelector(".about-container");
        setAboutTreshold(about.getBoundingClientRect().top + 100);
    }
    , []);

    return (
        <div className="about-container">
            <div className="about-main-container">
                <img src="https://assets-global.website-files.com/65d0f74d0d26c81a885c2794/65d0fb36e2e33453afabe95c_Quote%20Icon.svg" alt="" />
                <div className="about-main-container-texts">
                    <p>Üç genç girişimci olarak kurduğumuz dijital ajansımızı, yaratıcı ve yenilikçi çözümlerle öne çıkartıyoruz. Müşteri odaklı yaklaşımımızla projelerimizi hayata geçiriyor ve markaları dijital dünyada başarıya taşımayı hedefliyoruz.</p>
                    <h3>Founders of MEN Agency</h3>
                </div>
            </div>
        </div>
    );
};

export default About;