import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

    const [workTreshold, setWorkTreshold] = useState(null);
    const [imgWrapperTreshold, setImgWrapperTreshold] = useState(null);
    const [aboutTreshold, setAboutTreshold] = useState(null);
    const [contactTreshold, setContactTreshold] = useState(null);
    
    return (
        <AppContext.Provider
        value={{
            workTreshold,
            setWorkTreshold,
            imgWrapperTreshold,
            setImgWrapperTreshold,
            aboutTreshold,
            setAboutTreshold,
            contactTreshold,
            setContactTreshold,
        }}
        >
        {children}
        </AppContext.Provider>
    );
    };