import React, { useState, useEffect } from "react";
import "./ImgWrapper.css";

const ImgWrapper = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [checkMobile, setCheckMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setCheckMobile(true);
    }
  }, []);


  const images = [
    "https://resmim.net/cdn/2024/04/14/fg6Zq2.webp",
    "https://resmim.net/cdn/2024/04/14/fg6Wdx.webp",
    "https://resmim.net/cdn/2024/04/14/fg6mik.webp",
    "https://resmim.net/cdn/2024/04/14/fg6DGF.webp",
    "https://resmim.net/cdn/2024/04/14/fg6XyC.webp",
    "https://resmim.net/cdn/2024/04/14/fg6jIG.webp",
    "https://resmim.net/cdn/2024/04/14/fg6BjM.webp"
  ];

  const mobileImages = [
    "https://resmim.net/cdn/2024/04/14/fgQvFh.webp",
    "https://resmim.net/cdn/2024/04/14/fgVZRM.webp",
    "https://resmim.net/cdn/2024/04/14/fgVfnQ.webp",
    "https://resmim.net/cdn/2024/04/14/fgVmN3.webp",
    "https://resmim.net/cdn/2024/04/14/fgVsA1.webp",
    "https://resmim.net/cdn/2024/04/14/fgV4ZT.webp",
    "https://resmim.net/cdn/2024/04/14/fgVIAq.webp"
    
  ];

  useEffect(() => {
    const imgContainer = document.querySelector(".img-wrapper-container");
    const imgContent = document.querySelector(".img-wrapper-content");
    const imgGallery = document.querySelector(".img-wrapper-gallery");
    const initialWidth = imgGallery.offsetWidth;
    const finalWidth = initialWidth * 0.6;
    const scrollThreshold = imgContainer.offsetTop;

    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const range = Math.max(0, scrollY - scrollThreshold);
      const speed = 1;
      const newWidth = Math.max(finalWidth, initialWidth - range * speed);
      imgGallery.style.width = `${newWidth}px`;
      imgGallery.style.height = `${newWidth * 0.6}px`;
      if (window.innerWidth < 768) {
        imgGallery.style.width = `${newWidth}px`;
        imgGallery.style.height = `${newWidth * 3}px`;
      }
      if (window.innerHeight < 700) {
        imgGallery.style.height = `${newWidth * 2.5}px`;
      }
      setTimeout(() => {
        imgContent.style.top = "5%";
      }, 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const text = document.querySelector(".img-wrapper-text");
    const imgContainer = document.querySelector(".img-wrapper-container");

    const handleScroll = () => {
      if (window.scrollY > imgContainer.offsetTop) {
        const scrollY = window.scrollY || window.pageYOffset;
        const threshold =
          imgContainer.offsetTop +
          imgContainer.offsetHeight -
          window.innerHeight;
        const distanceFromBottom = threshold - scrollY;
        const maxDistance = imgContainer.offsetHeight - window.innerHeight;
        const percentage = (distanceFromBottom / maxDistance) * 100;
        var translateX = Math.max(-50, -10 + percentage);
        if (window.innerWidth < 768) {
          translateX = Math.max(-50, -50 + percentage);
        }
        text.style.transform = `translateX(${translateX}%)`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 500);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div className="img-wrapper-container">
        <div className="img-wrapper-content">
          <div className="img-wrapper-text">
            <h1> Geleceğini parlatmak için hazır mısın</h1>
          </div>
          <div className="img-wrapper-gallery">
            <img src={checkMobile ? mobileImages[currentImageIndex] : images[currentImageIndex]} alt="" />
          </div>
        </div>
      </div>

      <div className="projects-number-section">
        <div className="projects-number-section-content">
          <div className="projects-number-section-left">
            <p>
              By pooling our professional skills, engaging in thoughtful
              discussions, we cultivated a distinctive collaborative environment
              among colleagues diligently working together.
            </p>
          </div>
          <div className="projects-number-section-right">
            <div className="realized-projects">
              <div className="realized-projects-number">
                <h4>50</h4>
              </div>
              <div className="realized-projects-text">
                <p>realized projects</p>
              </div>
            </div>
            <div className="team-member">
              <div className="team-member-number">
                <p>3</p>
              </div>
              <div className="team-member-text">
                <p>team members</p>
              </div>
            </div>
          </div>
        </div>
        <div className="projects-number-bottom">
          <h3>
            Driven by passion, a group of young people are ready to create
            something truly remarkable
          </h3>
        </div>
      </div>
    </>
  );
};

export default ImgWrapper;
