import React, { useContext, useEffect, useState } from "react";
import "./Nav.css";
import mobileNavVideo from "../../assets/video/mobileNav-video.mp4";
import { AppContext } from "../../context/AppContext";



const Nav = () => {
  const [navMobile, setNavMobile] = useState(false);
  const { workTreshold, aboutTreshold, newsTreshold, contactTreshold } = useContext(AppContext);


  useEffect(() => {
    if (navMobile) {
      const logo = document.querySelector(".nav-content-logo");
      logo.style.opacity = "0";
      logo.style.transition = "all 0.8s ease-in-out";
    } else {
      const logo = document.querySelector(".nav-content-logo");
      logo.style.opacity = "1";
      logo.style.transition = "all 0.8s ease-in-out";
    }

  }, [navMobile]);


  useEffect(() => {
    const menuItem = document.querySelectorAll(".mobileNav-content-menu-item");
    menuItem.forEach((item) => {
      item.addEventListener("click", () => {
        setNavMobile(false);
      });
    });
  }, [navMobile]);


  return (
    <>
      <div
        className={`mobileNav-container ${navMobile ? "navMobile-active" : ""}`}
      >
        <div className="mobileNav-container-videoBg">
          <video playsInline autoPlay loop muted>
            <source src={mobileNavVideo} type="video/mp4" />
          </video>
        </div>
        <div className="mobileNav-content">
          <div className="mobileNav-content-menu">
            <div onClick={() => {
              window.scrollTo({
                top: workTreshold,
                behavior: "smooth",
              });
            }} className="mobileNav-content-menu-item" id="menu-work">
              <a href="#">Work</a>
            </div>
            <div onClick={() => {
              window.scrollTo({
                top: aboutTreshold,
                behavior: "smooth",
              });
            }} className="mobileNav-content-menu-item" id="menu-about">
              <a href="#">About</a>
            </div>
            <div className="mobileNav-content-menu-item" id="menu-news">
              <a href="#">News</a>
            </div>
            <div onClick={() => {
              window.scrollTo({
                top: contactTreshold,
                behavior: "smooth",
              });
            }} className="mobileNav-content-menu-item" id="menu-contact">
              <a href="#">Contact</a>
            </div>

            <div className="mobileNav-line"></div>
            <div className="mobileNav-socialMedia-container">
              <div className="mobileNav-socialMedia-content">
                <div className="mobileNav-socialMedia-item">
                  <a href="#">
                    <img src="https://i.hizliresim.com/itsszek.png" alt="" />
                  </a>
                </div>
                <div className="mobileNav-socialMedia-item">
                  <a href="#">
                    <img src="https://i.hizliresim.com/28bktxu.png" alt="" />
                  </a>
                </div>
                <div className="mobileNav-socialMedia-item">
                  <a href="#">
                    <img src="https://i.hizliresim.com/q3ca66z.png" alt="" />
                  </a>
                </div>
                <div className="mobileNav-socialMedia-item">
                  <a href="#">
                    <img src="https://i.hizliresim.com/63nj6fr.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div className="mobileNav-underText">
              <div className="mobileNav-underText-content">
                <h1>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Dignissimos, aspernatur?
                </h1>
                <h1>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Dignissimos, aspernatur?
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nav-container">
        <div className="nav-content">
          <div className="nav-content-logo">
            <p>MEN</p>
          </div>

          <div className="nav-content-menu">
            <div onClick={() => {
              window.scrollTo({
                top: workTreshold,
                behavior: "smooth",
              });
            }} className="nav-content-menu-item">
              <a href="#">Work</a>
            </div>
            <div onClick={() => {
              window.scrollTo({
                top: aboutTreshold,
                behavior: "smooth",
              });
            }} className="nav-content-menu-item">
              <a href="#">About</a>
            </div>
            <div className="nav-content-menu-item">
              <a href="#">News</a>
            </div>
            <div onClick={() => {
              window.scrollTo({
                top: contactTreshold,
                behavior: "smooth",
              });
            }} className="nav-content-menu-item">
              <a href="#">Contact</a>
            </div>
          </div>

          <div
            className="hamburger-menu"
            onClick={() => {
              setNavMobile(!navMobile);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
