import React, { useContext, useEffect, useState } from "react";
import men from "../../assets/video/9ef383ab-e5d0b1c7.mp4";
import "./Home.css";
import { AppContext } from "../../context/AppContext";

const Home = () => {

  const { workTreshold, aboutTreshold, newsTreshold, contactTreshold } = useContext(AppContext);

  return (
      <div className="home-container">
        <div className="home-content">
          <div className="home-video">
            <video playsInline autoPlay loop muted>
              <source
              src={men}
                type="video/mp4"
              />
            </video>
          </div>

          <div className="home-title-group">
            <div className="home-subtitle">
              <h4>
                Fikirlerinizi gerçeğe dönüştürmek için atacağınız adımlarla,
                yeni bir başlangıca hazır olun. Dijital dünyada öne çıkarak
                fark yaratın, keşfedin ve büyümeye hazır olun. Potansiyelinizi
                keşfedin!
              </h4>
            </div>
          </div>

          <div className="home-scroll-bottom">
            <div className="home-scroll-bottom-content">
              <div className="scroll-bottom-line"></div>
              <div onClick={() => {
                window.scrollTo({
                  top: workTreshold,
                  behavior: "smooth",
                });
              }} className="scroll-bottom-text">
                <h4>Daha fazlasını keşfedin</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Home;
