import React, { useContext, useEffect } from "react";
import "./Footer.css";
import { AppContext } from "../../context/AppContext";

const Footer = () => {
  const { contactTreshold, setContactTreshold } = useContext(AppContext);

  useEffect(() => {
    const footer = document.querySelector(".footer-container");
    setContactTreshold(footer.getBoundingClientRect().top + 100);
  }, []);

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-upper-group">
          <div className="footer-title">
            <h1>
              Bizimle <br />
              iletişime geç
            </h1>
          </div>

          <div className="footer-circle">
            <h4>Bize Ulaş</h4>
          </div>
        </div>

        <div className="footer-bottom-group">
          <div className="footer-bottom-col">
            <div className="footer-bottom-col-title">
              <h3>Sosyal Medya</h3>
            </div>

            <div className="footer-bottom-col-content">
              <ul>
                <li>Instagram</li>
                <li>Facebook</li>
                <li>Twitter</li>
              </ul>
            </div>
          </div>

          <div className="footer-bottom-col">
            <div className="footer-bottom-col-title">
              <h3>Adres</h3>
            </div>

            <div className="footer-bottom-col-content">
              <ul>
                <li>+90 533 025 1994</li>
                <li>info@agencyofmen.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
